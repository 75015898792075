import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Pagination from '../../components/Common/Pagination';
import { FaFileDownload } from '@react-icons/all-files/fa/FaFileDownload';

import DashboardLayout from '../../components/layouts/DashboardLayout';
import WalletDepositModal from '../../components/Payment/WalletDepositModal';
import PrivateRoute from '../../components/Security/privateRoute';
import CustomTable from '../../components/Common/Table';

import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';
import { selectMyDetails, selectUserTransactions } from '@a1-ict/fsbo/userSlice/selectors';
import { downloadInvoice, fetchUserTransactions } from '@a1-ict/fsbo/userSlice';
import { settings } from '../../settings';

const Invoices = () => {
  const { open } = useDialog('walletDeposit');
  const dispatch = useDispatch();

  const { balance, transactions } = useSelector(selectUserTransactions);
  const userDetails = useSelector(selectMyDetails);

  const items = transactions && transactions.items;

  useEffect(() => {
    dispatch(fetchUserTransactions({ dispatch, settings, queryParams: {} }));
  }, []);

  const handlePageClick = (data) => {
    dispatch(
      fetchUserTransactions({
        dispatch,
        settings,
        queryParams: { CurrentPage: data.selected + 1 },
      })
    );
  };

  const columns = [
    {
      label: 'Дата',
      path: 'create_timestamp',
    },
    {
      label: 'Вид плащане',
      content: (row) => <div>{row.package_title || row.property_title}</div>,
    },
    {
      label: 'Начин на плащане',
      path: 'type',
    },
    { label: 'Сума', content: (row) => <div>{row.amount}</div> },
    { label: 'Статус', path: 'status' },
    {
      label: 'Фактура',
      content: (row) => {
        return row.download_invoice ? (
          <div
            className="wallet_section__table-header-center"
            onClick={async () => {
              await dispatch(downloadInvoice({ id: row.id }));
            }}
          >
            <FaFileDownload style={{ cursor: 'pointer' }} color="#8593A9" size={19} />
          </div>
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <PrivateRoute>
      <DashboardLayout title="Портфейл">
        <div className="wallet_section mb-5">
          <WalletDepositModal walletId={userDetails.id} />
          <Row className=" justify-content-between ">
            {userDetails?.id && (
              <Col xs={12}>
                <h6 className="">
                  Номер на портфейл: <b>{userDetails.id}</b>
                </h6>
              </Col>
            )}

            <Col
              style={{ height: '35px', maxWidth: '400px' }}
              xs={12}
              sm={7}
              className="p-sm-2 px-2 py-0 wallet_section__wallet d-flex justify-content-between align-items-center mb-4"
            >
              <div className="wallet_bg"></div>
              <div className="wallet_bg_text py-2 mr-2">
                <span className="">Наличност</span>
              </div>

              <h5 className="text-end mr-2 my-0">{balance} Кредити</h5>
            </Col>
            <Col
              xs={5}
              className="d-flex px-0 justify-content-sm-end justify-content-start align-self-end mb-4"
            >
              <button onClick={open} className="btn animated">
                <span> Зареждане</span>
              </button>
            </Col>
            <Col xs={12} className="p-0 pt-2">
              <h5>История на плащанията</h5>
            </Col>
            <Col xs={12} className="bg-white radiused p-0 ">
              <CustomTable columns={columns} data={items || []}></CustomTable>
            </Col>

            {transactions?.total_pages > 1 && (
              <Col xs={12} className="d-flex justify-content-center my-3">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageCount={transactions.total_pages}
                  forcePage={0}
                />
              </Col>
            )}
          </Row>
        </div>
      </DashboardLayout>
    </PrivateRoute>
  );
};

export default Invoices;
