import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { usePayments } from './usePayments';

import { selectPromotePackages, selectWalletBonuses } from '@a1-ict/fsbo/paymentSlice/selectors';
import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';
import { fetchPromotePackages, fetchWalletBonuses } from '@a1-ict/fsbo/paymentSlice';

import { AiOutlineBank } from '@react-icons/all-files/ai/AiOutlineBank';

import ePay from '../../images/epay-large.png';
import easyPay from '../../images/easy-p-large.png';
import { hasInvoiceDetails } from '@a1-ict/fsbo/userSlice/requests';
import { selectHasInvoiceDetails } from '@a1-ict/fsbo/userSlice/selectors';
import InvoiceDetails from '../InvoiceDetails';
import { useReactToPrint } from 'react-to-print';
import { selectUserData } from '@a1-ict/core/authSlice';

const amounts = [20, 50, 100, 200];

const WalletDepositModal = ({
  dialogName = 'walletDeposit',
  showPredefinedPackages = false,
  walletId,
}) => {
  const dispatch = useDispatch();
  const { isOpen, toggle } = useDialog(dialogName);
  const { paymentTypes, postPaymentData } = usePayments();

  useEffect(() => {
    dispatch(fetchPromotePackages({ packageType: 'wallet' }));
    dispatch(fetchWalletBonuses({}));
    dispatch(hasInvoiceDetails());
  }, []);

  const invoiceDetailsFilled = useSelector(selectHasInvoiceDetails);

  const [amount, setAmount] = useState('');
  const [pack, setPack] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [bonusAmounts, setBonusCalc] = useState([]);
  const [curBonus, setCurBonus] = useState(null);
  const [predefinedAmounts, setPredefinedAmounts] = useState([]);

  const userDetails = useSelector(selectUserData);
  const packages = useSelector(selectPromotePackages);
  const bonuses = useSelector(selectWalletBonuses);

  const handlePayment = () => {
    if (amount == false) {
      toast.error('Моля, въведете сума от поне 1 лев', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    let paymentData = {};
    // if (pack) paymentData.package_id = pack;
    if (packages.length > 0) paymentData.package_id = packages[0].id;
    if (amount) paymentData.amount = parseFloat(amount);

    postPaymentData(paymentType, paymentData);
  };

  useEffect(() => {
    if (Object.keys(userDetails).length > 0) {
      if (bonuses?.total_items > 0) {
        setPredefinedAmounts(bonuses.items.map((amount) => amount.payment_amount));

        setBonusCalc(
          bonuses.items
            .filter((item) => item.bonus_percentage > 0)
            .map((item) => {
              return {
                amount: item.payment_amount,
                percentage: userDetails.a1_club_bonus_percentage
                  ? item.bonus_percentage + userDetails.a1_club_bonus_percentage
                  : item.bonus_percentage,
              };
            })
        );
      } else setBonusCalc([]);
    }
  }, [bonuses, userDetails]);

  useEffect(() => {
    let currentAmount = 0;
    let bonusPercentage = 1;
    if (!!amount) {
      let amountInt = parseFloat(amount);
      bonusAmounts.forEach((element) => {
        if (amountInt >= element.amount) {
          if (element.amount > currentAmount) {
            currentAmount = element.amount;
            bonusPercentage = element.percentage;
          }
        }
      });
    } else return setCurBonus(null);

    setCurBonus(bonusPercentage === 1 ? null : bonusPercentage);
  }, [amount]);

  const handleInput = (e) => {
    const { value } = e.target;
    const regex = /^(\s*|[1-9]\d*(\.)?(\d{0,2}))?$/;
    // let regex = /^[0-9]+$|^$/;

    if (
      e.target.getAttribute &&
      e.target.getAttribute('data-type') &&
      e.target.getAttribute('data-type') === 'number'
    ) {
      if (!value.match(regex)) {
        return;
      }
    }
    setAmount(value);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Modal isOpen={isOpen} toggle={toggle} scrollable={true}>
      <ModalHeader toggle={toggle}>Зареждане на портфейла</ModalHeader>
      <ModalBody>
        <div>
          <h6 className="h6">Изберете метод на плащане</h6>
          <div className="mb-4 wallet_section__deposit__payment">
            <div
              onClick={() => setPaymentType(paymentTypes.epay)}
              className={`wallet_section__deposit__payment__option ${
                paymentType === paymentTypes.epay ? 'active' : ' '
              } `}
            >
              <input
                type="radio"
                value={paymentTypes.creditCard}
                checked={paymentType === paymentTypes.epay}
                onChange={() => {}}
              />
              <div className="">
                <img src={ePay} alt="ePay" height="22px"></img>
                <img src={easyPay} alt="easyPay" height="34px"></img>
              </div>
            </div>
            {/* <div
              onClick={() => setPaymentType(paymentTypes.easyPay)}
              className={`wallet_section__deposit__payment__option ${
                paymentType === paymentTypes.easyPay ? 'active' : ' '
              } `}
            >
              <input
                type="radio"
                value={paymentTypes.creditCard}
                checked={paymentType === paymentTypes.easyPay}
                onChange={() => {}}
              />
              
              <span>Easypay</span>
            </div> */}
            <div
              onClick={() => setPaymentType(paymentTypes.bank)}
              className={`wallet_section__deposit__payment__option ${
                paymentType === paymentTypes.bank ? 'active' : ' '
              } `}
            >
              <input
                type="radio"
                value={paymentTypes.creditCard}
                checked={paymentType === paymentTypes.bank}
                onChange={() => {}}
              />
              <div className="d-flex flex-column">
                <AiOutlineBank size={34}></AiOutlineBank>
                <span>По банков път</span>
              </div>
            </div>
          </div>
        </div>
        {showPredefinedPackages && (
          <div>
            <h6 className="h6">Изберете брой точки</h6>
            <div className="mb-4 wallet_section__deposit__packages">
              {packages &&
                packages.length > 0 &&
                packages.map((p) => {
                  return (
                    <button
                      onClick={() => {
                        if (p.id === pack) setPack(null);
                        else setPack(p.id);
                      }}
                      className={` py-2 px-3 ${pack === p.id ? 'active' : ' '}`}
                      disabled={amount ? true : false}
                      key={p.id}
                    >
                      {p.name} / {p.price} лв.
                    </button>
                  );
                })}
            </div>
          </div>
        )}
        {paymentType ? (
          paymentType !== 'bank' ? (
            <>
              {invoiceDetailsFilled ? (
                <>
                  <div
                    style={{ flexFlow: 'wrap', gap: '.75rem' }}
                    className="mb-3 d-flex flex-row justify-content-center wallet_section__amounts"
                  >
                    {predefinedAmounts.map((a) => {
                      return (
                        <button
                          onClick={() => {
                            setAmount(a);
                          }}
                          className={` py-2 px-3 ${amount === a ? 'active' : ' '}`}
                          key={a}
                        >
                          {a.toFixed(2)}лв.
                        </button>
                      );
                    })}
                  </div>
                  <div className="d-flex justify-content-center mb-3">
                    * Посочените цени са в лева с ДДС
                  </div>
                  <div className="wallet_section__bonus">
                    {bonuses?.total_items > 0 &&
                      Object.keys(userDetails).length > 0 &&
                      bonuses.items.map((bonus) => {
                        if (bonus.bonus_percentage > 0) {
                          return (
                            <div key={bonus.id} className="">
                              Получавате{' '}
                              <b>
                                {userDetails.a1_club_bonus_percentage
                                  ? userDetails.a1_club_bonus_percentage + bonus.bonus_percentage
                                  : bonus.bonus_percentage}
                                %
                              </b>{' '}
                              бонус при депозит от минимум{' '}
                              <h6 className="h5">{bonus.payment_amount}лв.</h6>
                            </div>
                          );
                        }
                      })}
                  </div>
                  <p className="text-danger font-weight-bolder">
                    Важно! Бонусът се начислява към сумата без ДДС.
                  </p>
                  <h6 className="h6 mt-2">Въведете сума в лева с включен ДДС</h6>
                  <div className="mb-3 wallet_section__deposit__amount d-flex align-items-center">
                    <Input
                      type="text"
                      data-type="number"
                      name="amoumt"
                      style={{ color: 'black' }}
                      id="amount"
                      placeholder="Сума"
                      value={amount}
                      onChange={handleInput}
                      readOnly={pack ? true : false}
                    />
                    <div className="">
                      {!!amount && curBonus ? (
                        <div>
                          Вие ще получите{' '}
                          <span className="color-default h5">
                            {(Number(amount) + ((Number(amount) / 1.2) * curBonus) / 100).toFixed(
                              2
                            )}{' '}
                            кредити
                          </span>
                        </div>
                      ) : (
                        <div>
                          Вие ще получите{' '}
                          {amount && (
                            <span className="color-default h5 ">
                              {(
                                Number(amount) +
                                (userDetails.a1_club_bonus_percentage
                                  ? ((Number(amount) / 1.2) *
                                      userDetails.a1_club_bonus_percentage) /
                                    100
                                  : 0)
                              ).toFixed(2)}
                            </span>
                          )}
                          <span className="color-default h5"> кредити</span>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <InvoiceDetails />
              )}
            </>
          ) : (
            <div>
              <h6 className="h6">Банкова сметка</h6>
              <div ref={componentRef} className="mb-4 wallet_section__deposit__bank_grid">
                <div
                  style={{ gridColumnStart: 1, gridColumnEnd: 4, gridRowStart: 1, gridRowEnd: 2 }}
                  className="d-flex flex-column"
                >
                  <span style={{ fontSize: '0.75rem', color: 'grey' }}>Получател:</span>
                  <span style={{ fontWeight: 600 }}>Смарт Инвестор ЕООД </span>
                </div>
                <div
                  style={{ gridColumnStart: 1, gridColumnEnd: 3, gridRowStart: 2, gridRowEnd: 3 }}
                  className="d-flex flex-column"
                >
                  <span style={{ fontSize: '0.75rem', color: 'grey' }}>Сметка:</span>
                  <span style={{ fontWeight: 600 }}>BG43UNCR70001520940866</span>{' '}
                </div>
                <div
                  style={{ gridColumnStart: 3, gridColumnEnd: 4, gridRowStart: 2, gridRowEnd: 3 }}
                  className="d-flex flex-column"
                >
                  <span style={{ fontSize: '0.75rem', color: 'grey' }}>Bic</span>
                  <span style={{ fontWeight: 600 }}>UNCRBGSF </span>
                </div>
                <div
                  style={{ gridColumnStart: 1, gridColumnEnd: 4, gridRowStart: 3, gridRowEnd: 4 }}
                  className="d-flex flex-column"
                >
                  <span style={{ fontSize: '0.75rem', color: 'grey' }}>Банка на получателя</span>
                  <span style={{ fontWeight: 600 }}> УниКредит Булбанк АД </span>
                </div>
                <div
                  style={{ gridColumnStart: 1, gridColumnEnd: 3, gridRowStart: 4, gridRowEnd: 5 }}
                  className="d-flex flex-column"
                >
                  <span style={{ fontSize: '0.75rem', color: 'grey' }}>Валута:</span>
                  <span style={{ fontWeight: 600 }}>BGN</span>{' '}
                </div>
                <div
                  style={{ gridColumnStart: 3, gridColumnEnd: 4, gridRowStart: 4, gridRowEnd: 5 }}
                  className="d-flex flex-column"
                >
                  <span style={{ fontSize: '0.75rem', color: 'grey' }}>Сума:</span>
                  <span></span>{' '}
                </div>
                <div
                  style={{ gridColumnStart: 1, gridColumnEnd: 4, gridRowStart: 5, gridRowEnd: 6 }}
                  className="d-flex flex-column"
                >
                  <span style={{ fontSize: '0.75rem', color: 'grey' }}>Основание:</span>
                  <span style={{ fontWeight: 600 }}>Имот.com Портфейл номер {walletId}</span>{' '}
                </div>
              </div>
              <div className="text-center mb-2">
                <button onClick={handlePrint} className="btn animated">
                  <span>Разпечатай</span>
                </button>
              </div>
              <div className="">
                След получаване на банковото потвърждение, Вашите кредити ще бъдат заредена в
                Портфейла Ви във Имот.com
              </div>
            </div>
          )
        ) : null}
      </ModalBody>
      <ModalFooter className="justify-content-center wallet_section__modal__footer">
        {paymentType && paymentType !== 'bank' && !!invoiceDetailsFilled && (
          <button className="btn animated" onClick={handlePayment}>
            <span>Зареди сметка</span>
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default WalletDepositModal;
